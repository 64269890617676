.content-text {
  font-size: 16px;
  line-height: calc(25/16);
  font-weight: 400;
  color: #9F9F9F;

  b {
    font-weight: 700;
  }

  @media (max-width: 991px) {
    font-size: 14px;
    line-height: (20/14);
  }
}