@import 'src/styles/vars.scss';
@import "src/styles/components/title.scss";
@import "src/styles/components/buttons.scss";
@import "src/styles/components/text.scss";
@import "src/styles/components/slider.scss";

body {
  font-family: $manrope;
  overflow-x: hidden;
  background: #030303;
  color: #fff;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

section {
  padding: 80px 0;
  position: relative;

  @media (max-width: 990px) {
    padding: 60px 0;
  }

  @media (max-width: 767px) {
    padding: 50px 0;
  }

  @media (max-width: 600px) {
    padding: 25px 0;
  }
}

.container {
  margin: 0 auto;
  width: 100%;
  padding: 0 30px;
  max-width: 1200px;
  position: relative;
  z-index: 1;

  @media (max-width: 600px) {
    padding: 0 15px;
  }

  @media (max-width: 400px) {
    padding: 0 10px;
  }
}

ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.hidden {
  display: none !important;
}

img, picture {
  max-width: 100%;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(5px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}