.slick-dots {
  bottom: -35px;

  li {
    margin: 0;
    width: auto;
    height: auto;

    .slider-dot {
      border-radius: 50%;
      background: #687893;
      width: 10px;
      height: 10px;
    }

    &.slick-active {
      .slider-dot {
        background: #0346F1;
      }
    }

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}