//fonts
$manrope: 'Manrope', sans-serif;
$inter: 'Inter', sans-serif;
$nunitoSans: 'Nunito Sans', sans-serif;

$headerHeight: 95px;

//colors
$buttonClassic: #0346F1;

//mixins
@mixin btn-border-transparent {
  border: 1px solid rgba(169, 184, 207, 0.2);
  transition: .3s ease;
  background-color: transparent;

  &:hover {
    background-color: rgba(169, 184, 207, 0.2);
  }

  &:active {
    background-color: rgba(169, 184, 207, 0.3);
  }
}

@mixin showVisible {
  opacity: 1;
  visibility: visible;
}

@mixin hideVisible {
  opacity: 0;
  visibility: hidden;
}