@import "src/styles/vars.scss";

.main-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 2147483650;
  transition: .5s ease .5s;

  &[data-is-loaded="true"] {
    @include hideVisible;
  }
}

.preloader {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: #030303;
  z-index: 69;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 1;
  visibility: visible;
  transition: 1s all;

  &.done {
    opacity: 0;
    visibility: hidden;
  }

  &__box {
    border-radius: 50%;
    width: 200px;
    height: 200px;
    filter: url("#goo");
    animation: rotateMove 2s ease-in-out infinite;
    margin: 0 0 30px;
  }

  &__bg-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    display: block;
    z-index: -1;
    width: 300px;
    height: 300px;
    background: blue;
    filter: blur(100px);

    @media (max-width: 600px) {
      filter: blur(60px);
      height: 250px;
      width: 250px;
    }
  }

  &__perc {
    font-size: 25px;
    color: #fff;
    font-family: $nunitoSans;

    &_num {
      font-size: 50px;
      font-family: $manrope;
    }
  }

  &__dot {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: #000;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  &__dot-3 {
    background-color: #FFFFFF;
    animation: dot3move 2s ease infinite, index 6s ease infinite;
  }

  &__dot-2 {
    background-color: #0346F1;
    animation: dot2move 2s ease infinite, index 6s -4s ease infinite;
  }

  &__dot-1 {
    background-color: #5685FF;
    animation: dot1move 2s ease infinite, index 6s -2s ease infinite;
  }
}

@keyframes dot3move {
  20% {
    transform: scale(1);
  }

  45% {
    transform: translateY(-18px) scale(0.45);
  }

  60% {
    transform: translateY(-90px) scale(0.45);
  }

  80% {
    transform: translateY(-90px) scale(0.45);
  }

  100% {
    transform: translateY(0px) scale(1);
  }
}

@keyframes dot2move {
  20% {
    transform: scale(1);
  }

  45% {
    transform: translate(-16px, 12px) scale(0.45);
  }

  60% {
    transform: translate(-80px, 60px) scale(0.45);
  }

  80% {
    transform: translate(-80px, 60px) scale(0.45);
  }

  100% {
    transform: translateY(0px) scale(1);
  }
}

@keyframes dot1move {
  20% {
    transform: scale(1);
  }

  45% {
    transform: translate(16px, 12px) scale(0.45);
  }

  60% {
    transform: translate(80px, 60px) scale(0.45);
  }

  80% {
    transform: translate(80px, 60px) scale(0.45);
  }

  100% {
    transform: translateY(0px) scale(1);
  }
}

@keyframes rotateMove {
  55% {
    transform: rotate(0deg);
  }

  80% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes index {
  0%,
  100% {
    z-index: 3;
  }

  33.3% {
    z-index: 2;
  }

  66.6% {
    z-index: 1;
  }
}
