.modal-wrap{
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000d23a6;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  z-index: 25;
  animation: zoomIn .5s;

  &--closed{
    animation: zoomOut .5s;
  }
}

.modal{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  text-align: center;
  max-width: 500px;
  width: 100%;
  padding: 39px;
  margin: 10px;
  border-radius: 5px;

  @media (max-width: 767px) {
    padding: 22px 17px;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
    transition: all .3s ease;
    cursor: pointer;

    &:hover {
      opacity: .6;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 40px;
    line-height: (59/40);
    color: #fff;
  }

  &__text {
    font-size: 17px;
    line-height: (23/17);
    text-transform: uppercase;
    color: #8DA0C2;
    font-weight: 700;
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  to {
    opacity: 0;
  }
}