.title {
  font-family: $nunitoSans;
  font-weight: 700;
  color: #fff;

  &--main {
    font-size: 50px;
    line-height: (60/50);

    @media (max-width: 1259px) {
      font-size: 45px;
    }

    @media (max-width: 991px) {
      font-size: 40px;
    }

    @media (max-width: 767px) {
      font-size: 35px;
    }
  }

  &--second {
    font-size: 40px;
    line-height: 1;

    @media (max-width: 1259px) {
      font-size: 36px;
    }

    @media (max-width: 991px) {
      font-size: 30px;
    }

    @media (max-width: 767px) {
      font-size: 27px;
    }
  }

  &--center {
    text-align: center;
  }

  @media (max-width: 991px) {
    text-align: center;
  }
}

.title-subtext {
  @media (max-width: 991px) {
    text-align: center;
  }
}