@import "src/styles/vars.scss";
@import "src/styles/modal.scss";

@import "src/styles/libs/reset";

@import "src/styles/libs/slick";
@import "src/styles/libs/slick-theme";

@import "src/styles/fonts.scss";

@import "src/styles/main.scss";

body {
  min-height: 100vh;
}