@import "src/styles/vars.scss";


.notAvailable {
  &__wrapper {
    height: 100vh;
    width: 100%;
    background: linear-gradient(252.85deg, #1743AD 0%, #1451E3 63.02%, #387AE1 100%);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column-reverse;

    @media (min-width: 992px) {
      flex-direction: row;
    }
  }

  &__text {
    max-width: 294px;
    flex-shrink: 0;

    @media (min-width: 992px) {
      max-width: 397px;
    }
  }

  &__title {
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 992px) {
      font-size: 25px;
      margin-bottom: 35px;
      justify-content: flex-start;
    }

    svg {
      margin-right: 16px;

      @media (min-width: 992px) {
        margin-right: 20px;
      }

      path {
        fill: #fff;
      }
    }
  }

  &__subtitle {
    font-size: 18px;
    line-height: calc(27/18);
    font-weight: 500;
    text-align: center;

    @media (min-width: 992px) {
      font-size: 28px;
      line-height: calc(32/28);
      text-align: left;
    }
  }

  &__image {
    max-width: 352px;

    @media (min-width: 992px) {
      max-width: 654px;
    }

    img {
      height: auto;
      width: 100%;
    }
  }
}
