.button {
  font-family: $manrope;
  font-size: 16px;
  line-height: 1;
  transition: .3s ease;
  color: #fff;
  cursor: pointer;
  font-weight: 500;
  border-radius: 5px;
  display: inline-block;
  padding: 15px;
  text-align: center;
  white-space: nowrap;

  @media (max-width: 991px) {
    font-size: 14px;
  }

  &--classic {
    background: $buttonClassic;
    border: none;
    min-width: 160px;

    &:hover {
      background: #4D7EFD;
    }

    &:active {
      background: #fff;
      color: $buttonClassic;
    }
  }

  &--no-border {
    display: inline-flex;
    align-items: center;

    span {
      color: #fff;
    }

    svg {
      margin-left: 20px;
    }
  }

  &--with-border {
    border: 1px solid #0346F1;

    &:hover {
      border: 1px solid #4D7EFD;
      background: #4D7EFD;
    }

    &:active {
      background: #fff;
      border: 1px solid #fff;
      color: #0346F1;
    }
  }
}